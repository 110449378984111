import {userStore} from '@/store/__STORE_user';
export default {
  computed: {
    authUser: () => userStore.profileData,
  },
  methods: {
    formatMoney(amount) {
      if (this.authUser && this.authUser.account) {
        return this.authUser.account.currency.sign + parseFloat(amount).toFixed(2);
      }

      return '';
    },
  },
};
